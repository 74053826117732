import React, { useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import { Row, Col } from 'antd';
import bellCurve from "highcharts/modules/histogram-bellcurve";
import { RendererWrapper, CustomHighChart } from '.';
bellCurve(Highcharts);

export const PropertiesHistogram = ({ data }) => {
    const getOptions = useCallback((key) => {
        return {
            chart: { type: 'column' },
            title: {
                text: key.split('_').join(' '),
                style: {
                    fontSize: '16px'
                },
            },
            subtitle: { text: '' },
            legend: {
                enabled: false
            },
            xAxis: [{
                title: { text: '' },
                alignTicks: false,
                visible: false
            }, {
                title: { text: '' },
                alignTicks: true,
            }],

            yAxis: [{
                title: { text: '' },
                visible: false
            }, {
                title: { text: '' },
            }],

            plotOptions: {
                histogram: {
                    accessibility: {
                        point: {
                            valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                        }
                    }
                },
            },

            series: [{
                name: 'Histogram',
                type: 'histogram',
                xAxis: 1,
                yAxis: 1,
                baseSeries: 's1',
                zIndex: -1
            }, {
                name: 'Data',
                type: 'scatter',
                data: Object.values(data[key]).map(value => value ? value : 0),
                id: 's1',
                marker: {
                    radius: 1.5,
                    enabled: false
                }
            },
            ],
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
        }
    }, [data])

    const histogramData = useMemo(() => {
        return Object.keys(data).map(key => getOptions(key))
    }, [getOptions, data])

    return (
        <RendererWrapper heading='PROPERTIES HISTOGRAM'>
            <Row gutter={[16, 16]}>
                {
                    histogramData.map((options, i) => {
                        return (
                            <Col key={i} span={12}>
                                <CustomHighChart
                                    style={{ width: 'auto' }}
                                    highcharts={Highcharts}
                                    options={options}
                                />
                            </Col>
                        )
                    })
                }
            </Row>
        </RendererWrapper>
    )
};
