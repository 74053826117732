import { blue } from '@ant-design/colors';
import { Button, Form, Input } from 'antd';
import React from "react";

export const NameAndCategoryForm = ({ setUnit, next, unit }) => {
    const [form] = Form.useForm();
    const handleFinish = () => next()

    return (
        <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
                name="Name"
                label="Name"
                initialValue={unit.name}
                rules={[{ required: true }]}
            >
                <Input
                    placeholder='eg: kg'
                    onChange={(e) => setUnit(prev => ({ ...prev, name: e.target.value }))}
                />
            </Form.Item>
            <Form.Item
                name="Category"
                label="Category"
                initialValue={unit.category}
                rules={[{ required: true }]}
            >
                <Input
                    placeholder='eg: weight'
                    onChange={(e) => setUnit(prev => ({ ...prev, category: e.target.value }))}
                />
            </Form.Item>
            <Button htmlType='submit' style={{ background: blue.primary, color: '#fff' }}>
                Next
            </Button>
        </Form>
    )
}