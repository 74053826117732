import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Select } from 'antd';
import Highcharts from 'highcharts';
import { RendererWrapper, CustomHighChart } from '.';

export const AllDataScatter = ({ data }) => {
    const selectionOptions = Object.keys(data).map(value => ({ value, label: value }))

    const [selectionX1, setselectionX1] = useState()
    const [selectionY1, setselectionY1] = useState()
    const [selectionX2, setselectionX2] = useState()
    const [selectionY2, setselectionY2] = useState()
    const [plotData1, setplotData1] = useState([1, 2, 3])
    const [plotData2, setplotData2] = useState([1, 2, 3])

    const highchartsOptions1 = useMemo(() => {
        const options = {
            chart: { type: "scatter" },
            title: { text: '' },
            xAxis: {
                title: { text: selectionX1 }
            },
            yAxis: {
                title: { text: selectionY1 }
            },
            plotOptions: {
                scatter: {
                    tooltip: {
                        pointFormat: `{point.exp}<br><br> x:{point.x}<br> y: {point.y}`
                    }
                }
            },
            series: [{
                name: "Experiments",
                data: plotData1
            }]
        }
        return options
    }, [plotData1, selectionX1, selectionY1])

    const highchartsOptions2 = useMemo(() => {
        const options = {
            chart: { type: "scatter" },
            title: { text: '' },
            xAxis: {
                title: { text: selectionX2 }
            },
            yAxis: {
                title: { text: selectionY2 }
            },
            plotOptions: {
                scatter: {
                    tooltip: {
                        pointFormat: `{point.exp}<br><br> x:{point.x}<br> y: {point.y}`
                    }
                }
            },
            series: [{
                name: "Experiments",
                data: plotData2
            }]
        }
        return options
    }, [plotData2, selectionX2, selectionY2])


    useEffect(() => {
        const plot = data
        const data1 = plot[selectionX1] ?? {}
        const points1 = Object.values(data1)
        const experiments = Object.keys(data1)
        const data2 = plot[selectionY1] ?? {}
        const points2 = experiments.map(exp => data2[exp])

        const merged = points1.map((point, index) => ({ exp: experiments[index], x: point, y: points2[index] }))
        console.log("🚀 ~ file: DataAnalysis.js ~ line 41 ~ useEffect ~ merged", merged)
        setplotData1(merged)
    }, [selectionX1, selectionY1, data])

    useEffect(() => {
        const plot = data
        const data1 = plot[selectionX2] ?? {}
        const points1 = Object.values(data1)
        const experiments = Object.keys(data1)
        const data2 = plot[selectionY2] ?? {}
        const points2 = experiments.map(exp => data2[exp])

        const merged = points1.map((point, index) => ({ exp: experiments[index], x: point, y: points2[index] }))
        console.log("🚀 ~ file: DataAnalysis.js ~ line 41 ~ useEffect ~ merged", merged)
        setplotData2(merged)
    }, [selectionX2, selectionY2, data])

    return (
        <RendererWrapper heading='All Data Scatter'>
            <Row gutter={16}>
                <Col span={12}>
                    <Row gutter={6}>
                        <Col span={12}>
                            <Select placeholder="Select a Ingredient or Property" value={selectionY1} style={{ width: '100%' }} options={selectionOptions} onChange={setselectionY1} />
                        </Col>
                        <Col span={12}>
                            <Select placeholder="Select a Ingredient or Property" value={selectionX1} style={{ width: '100%' }} options={selectionOptions} onChange={setselectionX1} />
                        </Col>
                    </Row>
                    <CustomHighChart
                        highcharts={Highcharts}
                        options={highchartsOptions1}
                    />
                </Col>

                <Col span={12}>
                    <Row gutter={6}>
                        <Col span={12}>
                            <Select placeholder="Select a Ingredient or Property" value={selectionY2} style={{ width: '100%' }} options={selectionOptions} onChange={setselectionY2} />
                        </Col>
                        <Col span={12}>
                            <Select placeholder="Select a Ingredient or Property" value={selectionX2} style={{ width: '100%' }} options={selectionOptions} onChange={setselectionX2} />
                        </Col>
                    </Row>
                    <CustomHighChart
                        highcharts={Highcharts}
                        options={highchartsOptions2}
                    />
                </Col>
            </Row>
        </RendererWrapper>
    )
};
