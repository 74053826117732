import React from "react";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error('ERROR FROM ERROR BOUNDARIES', error, errorInfo);
    }
    render() {
        if (this.state.hasError) return this.props.fallback
        return this.props.children;
    }
}