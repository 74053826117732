import axios from "axios";
import { API_URL } from '../@constants'

export const tokenApi = (payload) => {
    return axios.post(`${API_URL}/token`, payload)
}


export const approveFilesApi = (filename) => {
    return axios.post(`${API_URL}/approve_file`, {
        user_name: localStorage.getItem('user_name'),
        email: localStorage.getItem('user_email'),
        filename
    },
        { headers: { token: localStorage.getItem('token') } })
}


export const getFileListApi = () => {
    return axios.post(`${API_URL}/files_list`, null,
        { headers: { token: localStorage.getItem('token') } })
}


export const fileUploadApi = (bodyFormData) => {
    return axios({
        method: 'post',
        url: `${API_URL}/uploads`,
        data: bodyFormData,
        headers: {
            'Content-Type': 'multipart/form-data', token: localStorage.getItem('token')
        }
    })
}


export const removeFileApi = (filename) => {
    return axios.post(`${API_URL}/remove_file`, {
        filename
    },
        { headers: { token: localStorage.getItem('token') } })
}


export const updateFileDetailsApi = (payload) => {
    payload.user_name = localStorage.getItem('user_name')
    payload.email = localStorage.getItem('user_email')
    return axios.post(`${API_URL}/update_file`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const getCompaniesListApi = () => {
    return axios.post(`${API_URL}/companies_list`, null,
        { headers: { token: localStorage.getItem('token') } })
}


export const getUsersListApi = (company_id) => {
    return axios.post(`${API_URL}/users_list`, { company_id },
        { headers: { token: localStorage.getItem('token') } })
}


export const accessControlApi = (payload) => {
    return axios.post(`${API_URL}/access_control`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const metricsConfigApi = () => {
    return axios.post(`${API_URL}/metrics/configs`, null,
        { headers: { token: localStorage.getItem('token') } })
}


export const metricsRetrieveApi = (payload) => {
    return axios.post(`${API_URL}/metrics/retrieve`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const metricsDashboardApi = (payload) => {
    return axios.post(`${API_URL}/metrics/dashboard`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const tablesCreateAPi = (payload) => {
    return axios.post(`${API_URL}/tables/create`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const tablesRetrieveAPi = (payload) => {
    return axios.post(`${API_URL}/tables/retrieve`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const tablesUpdateAPi = (payload) => {
    return axios.post(`${API_URL}/tables/update`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const deployDevModelsApi = (payload) => {
    return axios.post(`${API_URL}/dev/deploy_models`, payload,
        { headers: { token: localStorage.getItem('token') } })
}


export const approveInverseRequestApi = (payload) => {
    return axios.post(`${API_URL}/dev/approve_inverse_request`, payload,
        { headers: { token: localStorage.getItem('token') } })
}

export const encryptDecryptDataApi = (payload) => {
    return axios.post(`${API_URL}/_encrypt_decrypt_data`, payload,
        { headers: { token: localStorage.getItem('token') } })
}

export const dataAnalysisFileUpload = (payload) => {
    return axios.post(`${API_URL}/get_data_analytics`, payload, {
        headers: { token: localStorage.getItem('token') }
    })
}

export const getUnitConversionList = (payload) => {
    return axios.post(`${API_URL}/_list_unit`, payload, { headers: { token: localStorage.getItem('token') } })
}
export const deleteUnitConversion = (payload) => {
    return axios.post(`${API_URL}/_delete_unit`, payload, { headers: { token: localStorage.getItem('token') } })
}
export const addNewUnitConversion = (payload) => {
    return axios.post(`${API_URL}/_create_unit`, payload, { headers: { token: localStorage.getItem('token') } })
}
export const updateUnitConversion = (payload) => {
    return axios.post(`${API_URL}/_update_unit`, payload, { headers: { token: localStorage.getItem('token') } })
}

export const backupDatabase = (payload) => {
    return axios.put(`${API_URL}/backup_db`, payload, {
        headers: { token: localStorage.getItem('token') }
    })
}

export const restoreDatabase = (payload) => {
    return axios.patch(`${API_URL}/restore_db`, payload, {
        headers: { token: localStorage.getItem('token') }
    })
}

export const fetchNewsLetterList = () => {
    return axios.post(`${API_URL}/_list_news_letter_subscription`, null, {
        headers: { token: localStorage.getItem('token') }
    })
}

export const fetchCaseStudyList = () => {
    return axios.post(`${API_URL}/_list_case_study_download`, null, {
        headers: { token: localStorage.getItem('token') }
    })
}