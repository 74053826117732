import { blue } from '@ant-design/colors';
import { Button, Form, Input, Row, Tag } from 'antd';
import React, { useEffect, useState } from "react";

export const AlternateValuesForm = ({ unit, next, prev, setUnit }) => {
    const [form] = Form.useForm();
    const [alternativeValues, setAlternateValues] = useState(unit.alternative_values)

    useEffect(() => {
        setUnit(prev => ({ ...prev, alternative_values: alternativeValues }))
    }, [alternativeValues, setUnit])

    const handleFinish = () => next()

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            onKeyDown={(e) => {
                if (e.code === 'Enter') e.preventDefault()
            }}
        >
            <Form.Item
                name="Alternative Values"
                label="Alternative Values"
                style={{ marginBottom: '6px' }}
                onKeyDown={(e) => {
                    if (e.code === 'Enter' && !!e.target.value) {
                        setAlternateValues([...new Set([...alternativeValues, e.target.value.toLowerCase()])])
                        form.setFieldsValue({ 'Alternative Values': '' })
                    }
                }}
            >
                <Input
                    placeholder='eg: kilogram'
                />
            </Form.Item>
            {
                alternativeValues.map((value) => {
                    return (
                        <Tag
                            key={value}
                            onClose={() => {
                                setAlternateValues(alternativeValues.filter(item => item !== value))
                            }}
                            closable
                        >
                            {value}
                        </Tag>
                    )
                })
            }

            <Row style={{ marginTop: '24px' }}>
                <Button style={{ marginRight: '10px' }} htmlType='button' onClick={prev}>
                    previous
                </Button>

                <Button
                    htmlType='submit'
                    style={{ background: blue.primary, color: '#fff' }}
                >
                    Next
                </Button>
            </Row>
        </Form>
    )
}
