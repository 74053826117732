import { useEffect, useState } from 'react'
import { Space, Row, Col, Card, Button, notification, Spin, Typography, message } from 'antd'
import { useHistory, Prompt } from "react-router-dom"
import { tablesCreateAPi, tablesRetrieveAPi, tablesUpdateAPi } from '../../services/apis'
import EditorJs from 'react-editor-js'
import Table from 'editorjs-table'
import Paragraph from '@editorjs/paragraph'
import Marker from '@editorjs/marker'
import Undo from 'editorjs-undo'
import { convertArrayToCSV } from 'convert-array-to-csv'
const { Text } = Typography


export const DataEditor = ({ location: { file: filename } }) => {

    const [editorInstance, setEditorInstance] = useState()

    const { push } = useHistory()
    const [tablesData, setTablesData] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingState, setEditingState] = useState(false)

    useEffect(() => {
        if (!filename) {
            push('/files')
        } else {
            if (editorInstance) {
                tablesRetrieveAPi({ filename })
                    .then(res => {
                        const data = res.data.data.tables
                        setTablesData(data)
                        let renderData = []
                        if (data.length === 0) {
                            renderData = [{ type: 'table', data: { content: [['', ''], ['', '']] } }]
                        } else {
                            renderData = data.reduce((array, element) => [...array, { type: 'table', data: { content: element.data } }], [])
                        }
                        editorInstance.isReady.then(() => {
                            editorInstance.render({ blocks: renderData })
                        }).catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
            }
        }
    }, [filename, editorInstance, push])

    const promtNotification = (e) => {
        e.stopImmediatePropagation()
        if (e.ctrlKey && e.key.toLowerCase() === 'z') {
            message.success("Undo executed")
        }
        if (e.ctrlKey && e.key.toLowerCase() === 'y') {
            message.success("Redo executed")
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', promtNotification)
        return () => window.removeEventListener('keydown', promtNotification)
    }, [])

    const changeHandler = () => {
        setEditingState(true)
    }

    const saveData = () => {
        editorInstance.save().then(res => {
            if (res.blocks.length === 0 && tablesData.length === 0) {
                notification.error({ message: 'Add a Table in the editor', placement: 'bottomRight' })
            } else {
                const data = res.blocks.reduce((array, element, index) => [...array, element.data.content], [])
                if (tablesData.length === 0) {
                    setLoading(true)
                    tablesCreateAPi({ filename, data }).then((res) => {
                        if (res.status === 200) {
                            setLoading(false)
                            setEditingState(false)
                            notification.success({ message: 'Saved Records', placement: 'bottomRight' })
                        }
                    }).catch(err => setLoading(false))
                } else {
                    setLoading(true)
                    setEditingState(false)
                    tablesUpdateAPi({ filename, data }).then((res) => {
                        if (res.status === 200) {
                            setLoading(false)
                            notification.success({ message: 'Updated Records', placement: 'bottomRight' })
                        }
                    }).catch(err => setLoading(false))
                }
            }
        })
    }

    const convertCsv = () => {
        editorInstance.save().then(res => {
            const data = res.blocks.reduce((array, element) => [...array, element.data.content], [])
            // To remove the HTML content from the strings
            for (let i in data) {
                const array = data[i]
                for (let j in array) {
                    const array2 = array[j]
                    for (let k in array2) {
                        array2[k] = array2[k].replace(/( |<([^>]+)>)/ig || /&nbsp;/g, ' ').trim()
                    }
                }
            }
            data.forEach((element, index) => {
                const csv = convertArrayToCSV(element, { separator: ',' })
                var hiddenElement = document.createElement('a')
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
                hiddenElement.target = 'blank'
                hiddenElement.download = `Table${index + 1}.csv`
                hiddenElement.click()
            })
        })
    }


    return (
        <>
            <Prompt
                when={editingState}
                message="Unsaved changes. Your changes will be lost."
            />
            <Space direction="vertical" size="large" style={{ width: "100%", padding: 20 }}>
                <Row gutter={16} justify="space-between">
                    <Col span={10} style={{ minWidth: 750, marginBottom: 50 }}>
                        <iframe src={`pdf/viewer/web/viewer.html?file=${filename}`} title="Pdf" style={{ width: "750px", height: "700px" }}>
                        </iframe>
                    </Col>
                    <Col span={11} style={{ minWidth: 600 }}>
                        <Card title="Editor" extra={editingState ?
                            <Text strong type="danger">Unsaved changes</Text> : null}>
                            <Spin spinning={loading}>
                                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                    <EditorJs
                                        instanceRef={instance => setEditorInstance(instance)}
                                        onReady={() => {
                                            new Undo({ editor: editorInstance })
                                        }}
                                        onChange={changeHandler}
                                        tools={{
                                            table: {
                                                class: Table,
                                                inlineToolbar: true,
                                            },
                                            paragraph: Paragraph,
                                            Marker: {
                                                class: Marker,
                                                shortcut: 'CMD+SHIFT+M',
                                            }
                                        }}
                                    />
                                    <Space size="small" direction="horizontal">
                                        <Button type="primary" onClick={saveData}>{tablesData.length !== 0 ? 'Update' : 'Save'}</Button>
                                        <Button type="primary" onClick={convertCsv}>Save as csv</Button>
                                    </Space>
                                </Space>
                            </Spin>
                        </Card>
                    </Col>
                </Row>
            </Space>
        </>
    )
}
