import React, { useState } from "react";
import { blue } from '@ant-design/colors';
import { Button, Checkbox, Form, Input, message, Row } from 'antd';
import { restoreDatabase } from '../../services/apis'
import Text from 'antd/lib/typography/Text';

export const DatabaseRestore = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    async function handleRestoreDB(values) {
        if (!values['SIT'] && !values['UAT'] && !values['DEV']) return message.error('Kindly select an environment')
        try {
            setLoading(true)
            const response = await restoreDatabase(values)
            if (response.status === 200) return message.success("DB Sync started. Message will be sent to #core-devs once completed.")
            message.error('Something went wrong in the API code')
        } catch (err) {
            if (err.response.status === 401) return message.error('Secret key is incorrect!')
            if (err.response.status === 400) return message.error('Something went wrong in the API code')
            message.error('Something went wrong with the server')
        } finally {
            form.resetFields()
            setLoading(false)
        }
    }

    return (
        <Form
            form={form}
            onFinish={handleRestoreDB}
            style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '500px' }}
        >
            <Text style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                 <span style={{ fontSize: '18px' }}>
                     This tool will backup PROD data and restore it into the DB of the selected server(s).
                 </span>
                 <span style={{ fontSize: '12px' }}>
                     <span style={{ color: blue.primary }}>Note:</span> Tasks run in background so Restoring takes 2-3 minutes to show changes on DB.
                 </span>
             </Text>
            <Row>
                <Form.Item
                    initialValue={false}
                    valuePropName="checked"
                    name='SIT'
                    style={{ marginBottom: 0 }}
                >
                    <Checkbox>SIT</Checkbox>
                </Form.Item>
                <Form.Item
                    initialValue={false}
                    valuePropName="checked"
                    name='UAT'
                    style={{ marginBottom: 0 }}
                >
                    <Checkbox>UAT</Checkbox>
                </Form.Item>
                <Form.Item
                    initialValue={false}
                    valuePropName="checked"
                    name='DEV'
                    style={{ marginBottom: 0 }}
                >
                    <Checkbox>DEV</Checkbox>
                </Form.Item>
            </Row>

            <Form.Item
                name="key"
                rules={[{ required: true, message: 'Secret key is required!', }]}
                style={{ marginBottom: 0 }}
            >
                <Input placeholder='Secret Key' />
            </Form.Item >

            <Button
                disabled={loading}
                htmlType='submit'
                style={{ background: blue.primary, color: '#fff' }}
            >
                {loading ? "Restoring..." : "Restore Database"}
            </Button>
        </Form >
    )
};
