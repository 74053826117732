import React, { useState } from 'react'
import { Input, Button, message } from 'antd'
import { dataAnalysisFileUpload } from '../../services/apis'
import { toFixed } from '../../utils'

function parseData(data) {
    return {
        status: data.status,
        "Ingredients Analysis Table 1": JSON.parse(
            data["Ingredients Analysis Table 1"]
        ),
        "Properties Analysis Table 1": JSON.parse(
            data["Properties Analysis Table 1"]
        ),
        "Ingredients Ratio Analysis": JSON.parse(
            data["Ingredients Ratio Analysis"]
        ),
        "Ingredients Correlation": JSON.parse(data["Ingredients Correlation"]),
        "Properties Correlation": JSON.parse(data["Properties Correlation"]),
        "Properties Histogram": JSON.parse(data["Properties Histogram"]),
        "Ingredients Histogram": JSON.parse(data["Ingredients Histogram"]),
        "All Data Scatter": JSON.parse(data["All Data Scatter"]),
        ingredients_outlier: data['ingredients_outlier'],
        processing_outlier: data['processing_outlier'],
        properties_outlier: data['properties_outlier'],
        "original_data": data['amount_of_empty'] ? Object.keys(data['original_data']).reduce((acc, curr) => {

            const amountOfEmpty = data['amount_of_empty'][curr]
            const amountOfEmptyValue = typeof amountOfEmpty === 'number' ? toFixed(amountOfEmpty, 3) : amountOfEmpty

            acc[curr] = {
                "Amount of Empty": amountOfEmptyValue + '%',
                ...data['original_data'][curr]
            }
            return acc
        }, {}) : data['original_data'],
        "clean_data": data['clean_data'],
    }
}

const DataAnalysisUpload = ({ setData }) => {
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)

    async function handleUploadFile(e) {
        e.preventDefault()
        if (!file) return message.error('Kindly upload the file!')
        setLoading(true)

        const formData = new FormData()
        formData.append('file', file)
        try {
            const data = await dataAnalysisFileUpload(formData)
            setData(parseData(data.data))
        } catch (e) {
            console.error('ERROR WHILE UPLOADING DATA PROCESSING FILE', e);
            message.error('Internal server error!')
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleUploadFile} className="data-processing-form">
            <h1>Upload a file to do Analysis</h1>
            <Input required type='file' accept='.xlsx' onChange={(e) => setFile(e.target.files[0])} />
            <Button disabled={loading} htmlType='submit'>{loading ? 'Uploading...' : 'Upload'}</Button>
        </form>
    )
}

export default DataAnalysisUpload