import { useEffect, useState, useMemo } from 'react'
import { Space, Spin, Card, Form, message, Select, Input, Row, Col, Button, Descriptions } from 'antd'
import { useHistory } from "react-router-dom"
import { metricsConfigApi, getCompaniesListApi, metricsDashboardApi } from '../../services/apis'
import Plot from 'react-plotly.js'


export const Metrics = () => {

    const { push } = useHistory()
    const [companies, setCompanies] = useState([])
    const [companiesLoading, setCompaniesLoading] = useState(false)
    const [metricsConfig, setMetricsConfig] = useState({ metrics: [], time_ranges: [] })
    const [metricsConfigLoading, setMetrcisConfigLoading] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [plotsData, setPlotsData] = useState([])
    const [plotsDataLoading, setPlotsDataLoading] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        setMetrcisConfigLoading(true)
        metricsConfigApi().then((res) => {
            setMetrcisConfigLoading(false)
            setMetricsConfig(res.data.data)
        }).catch(err => {
            //             localStorage.clear()
            //             push('/')
        })
        setCompaniesLoading(true)
        getCompaniesListApi().then((res) => {
            if (res.status === 200) {
                setCompaniesLoading(false)
                setCompanies(res.data.result.data.companies)
            } else {
                message.error('Not able to access companies')
            }
        }).catch((err) => {
            //             localStorage.clear()
            //             push('/')
        })
        setPlotsDataLoading(true)
        metricsDashboardApi({}).then((res) => {
            setPlotsDataLoading(false)
            setPlotsData(res.data.data.dashboard)
            form.setFieldsValue({
                divisions: res.data.data.dashboard[0].divisions,
                metric: res.data.data.dashboard[0].metric,
                company_id: res.data.data.dashboard[0].company_id,
                duration: res.data.data.dashboard[0].duration,
                unit: res.data.data.dashboard[0].unit
            })
        }).catch(err => {
            //             localStorage.clear()
            //             push('/')
        })
    }, [push, form])

    const submitForm = (values) => {
        console.log('values', values)
        setPlotsDataLoading(true)
        metricsDashboardApi(values).then((res) => {
            setPlotsDataLoading(false)
            setPlotsData(res.data.data.dashboard)
        }).catch(err => setPlotsDataLoading(false))
    }

    const plots = useMemo(() => {
        const array = plotsData.map((res) => res)
        for (let i in array) {
            let plot = array[i]?.plots_data?.x
            for (let j in plot) {
                plot[j] = `${new Date(plot[j]).toLocaleDateString()} ${new Date(plot[j]).toLocaleTimeString()}`
            }
        }
        return array
    }, [plotsData])


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Spin spinning={metricsConfigLoading || companiesLoading || plotsDataLoading}>
                <Card style={{ padding: 10 }} title="Dashboard">
                    <Space direction="vertical" style={{ width: '100%', overflowX: 'auto' }}>
                        <Row gutter={16} justify="space-around" style={{ marginBottom: 50, marginTop: 50 }}>
                            <Col style={{ marginBottom: 50 }}>
                                <Form onFinish={submitForm} form={form}>
                                    <Row gutter={10}>
                                        <Col span={14} style={{ minWidth: 200 }}>
                                            <Form.Item label="Company" name="company_id" rules={[{ required: true }]}>
                                                <Select >
                                                    {companies?.map((res, index) => (
                                                        <Select.Option key={index} value={res.company_id}>{res.company_name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10} style={{ minWidth: 200 }}>
                                            <Form.Item label="Duration" name="duration" rules={[{ required: true }]}>
                                                <Input type="number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12} style={{ minWidth: 200 }}>
                                            <Form.Item label="Unit" name="unit" rules={[{ required: true }]}>
                                                <Select onChange={(e) => {
                                                    setDivisions(metricsConfig?.time_ranges.filter(res => res.unit === e)[0]?.divisions)
                                                    form.setFieldsValue({ 'divisions': metricsConfig.time_ranges.filter(res => res.unit === e)[0]?.divisions[0] })
                                                }}>
                                                    {metricsConfig.time_ranges.map((res, index) => (
                                                        <Select.Option key={index} value={res.unit}>{res.unit}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} style={{ minWidth: 200 }}>
                                            <Form.Item label="Division" name="divisions" rules={[{ required: true }]}>
                                                <Select>
                                                    {divisions?.map(res => (
                                                        <Select.Option value={res}>{res}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                </Form>
                            </Col>
                            <Col xl={8}>
                                <Space style={{ minWidth: 400, overflow: 'auto' }}>
                                    <Descriptions bordered column={1}>
                                        {Object.entries(plotsData[0] || {}).map(([key, value]) => (
                                            (key !== 'plots_data' && key !== 'metric') &&
                                            <Descriptions.Item label={key.charAt(0).toUpperCase() + key.slice(1)}>{value}</Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </Space>
                            </Col>
                        </Row>

                        <Card title="Plots" style={{ overflow: 'auto', minWidth: 500 }}>
                            <Row justify="space-around">
                                {plots.map(res => (
                                    <Col style={{ overflowX: 'auto', padding: 5 }}>
                                        <Plot config={{ responsive: true }}
                                            data={[
                                                {
                                                    x: res.plots_data.x,
                                                    y: res.plots_data?.y,
                                                    type: 'scatter',
                                                    mode: 'lines+markers',
                                                    marker: { color: 'blue' },
                                                },
                                            ]}
                                            layout={{
                                                yaxis: { title: 'Frequency' },
                                                width: 450, height: 450,
                                                title: `<b>${res.metric}</b>`,
                                                margin: {
                                                    b: 150,
                                                },
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Space>
                </Card>
            </Spin>
        </Space>
    )
}
