import React, { useMemo } from "react";
import { Table } from 'antd';
import { RendererWrapper } from '.'

export const IngredientsAnalysisTable = ({ data }) => {
    const cols = useMemo(() => {
        return [
            {
                title: '',
                dataIndex: 'ingredient',
                key: 'ingredient',
                fixed: 'left',
                render: (text) => <strong className='table-first-col'>{text}</strong>
            },
            ...Object.keys(data['% Inclusion in Formulations']).map(col => ({
                title: col,
                dataIndex: col,
                key: col,
                render: (text) => <span className='table-head-col'>{text}</span>
            }))
        ]
    }, [data])

    const dataSource = useMemo(() => {
        return Object.keys(data).map(key => ({
            key,
            ingredient: key,
            ...data[key]
        }))
    }, [data])

    return (
        <RendererWrapper heading='INGREDIENTS ANALYSIS TABLE'>
            <Table
                className='table'
                dataSource={dataSource}
                bordered
                pagination={false}
                columns={cols}
            />
        </RendererWrapper>
    );
};
