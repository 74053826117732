import React, { useState } from "react";

export const RendererWrapper = ({ children, heading }) => {
    const [isMinimized, setIsMinimized] = useState(true);

    return (
        <div className="container">
            <h3 className='table-external-heading' onClick={() => setIsMinimized(!isMinimized)}>
                {!isMinimized ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        width={24}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                )}
                {heading.toUpperCase()}
            </h3>
            {!isMinimized && children}
        </div>
    );
};
