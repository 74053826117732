import { useEffect } from "react"
import { Space, Card, Tabs } from "antd"
import { ApproveRequest } from './ApproveRequest'
import { DeployModel } from "./DeployModel"
import { history } from "../../Routes"
const { TabPane } = Tabs


export const ML = () => {

  useEffect(() => {
    const user_email = localStorage.getItem("user_email")
    if ((user_email !== "amit.borkar@polymerize.io" && user_email !== "neeraj.deshpande@polymerize.io")) {
      history.push("/")
    }
  }, [])

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Card>
        <Tabs defaultActiveKey="1" size="large">
          <TabPane key="1" tab="Deploy Model">
            <DeployModel />
          </TabPane>
          <TabPane key="2" tab="Approve Inverse Request">
            <ApproveRequest />
          </TabPane>
        </Tabs>
      </Card>
    </Space>
  )
}
