import React, { useReducer } from 'react'
import "antd/dist/antd.css"
import 'react-datasheet/lib/react-datasheet.css';
import { Routes } from './Routes'
import firebase from 'firebase'


// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCKQEbnqWm3Mr6HIsWRZ0MnRAeb_Upxs8s",
  authDomain: "polymerize-engineering-b1fac.firebaseapp.com",
  projectId: "polymerize-engineering-b1fac",
  storageBucket: "polymerize-engineering-b1fac.appspot.com",
  messagingSenderId: "566367077768",
  appId: "1:566367077768:web:0f3c0ccf93cfa7274ce63d"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const CollapsedContext = React.createContext()

const intialState = false
const reducer = (state, action) => {
  switch (action) {
    case 'setCollapsed':
      return !state
    default:
      return state
  }
}


const App = () => {

  const [collapsed, dispatch] = useReducer(reducer, intialState)
  return (
    <CollapsedContext.Provider value={{ collapsed, setCollapsed: dispatch }}>
      <Routes />
    </CollapsedContext.Provider>
  )
}

export default App