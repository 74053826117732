import React, { useState } from 'react';
import { blue, green } from '@ant-design/colors';
import { Button, Col, Form, Input, InputNumber, message, Row, Select, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';

const initialValue = {
    name: '',
    value: '',
    conversion_method: ''
}

export const ConversionMetricForm = ({ setUnit, prev, finish, unit }) => {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(initialValue)

    function handleAddMetric() {
        const metricAlreadyExist = unit.conversion_metric.find(metric => metric.key === `${unit.name}_to_${formData.name}`)

        if (metricAlreadyExist) return message.error('Metric already exist')

        setUnit((prev) => {
            return {
                ...prev,
                conversion_metric: [
                    ...prev.conversion_metric,
                    {
                        key: `${unit.name}_to_${formData.name}`,
                        value: formData.value,
                        conversion_method: formData.conversion_method
                    }
                ]
            }
        })
        setFormData(initialValue)
        form.resetFields()
    }

    function handleFinishForm() {
        if (unit.conversion_metric.length < 1) return message.error('Please add at least one conversion metric')
        finish()
    }

    return (
        <Form form={form} layout="vertical" name="control-hooks" onFinish={handleAddMetric}>
            <Form.Item
                name="Unit Name"
                label="Unit Name"
                rules={[{ required: true }]}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            >
                <Input
                    placeholder='eg: gm'
                />
            </Form.Item>

            <Form.Item
                name="Unit Value"
                label="Unit Value"
                rules={[{ required: true }]}
                onChange={(e) => setFormData(prev => ({ ...prev, value: e.target.value }))}
            >
                <InputNumber placeholder='eg: 1000' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="Unit Conversion Method"
                label="Unit Conversion Method"
                onChange={(e) => console.log(e.target)}
                rules={[{ required: true }]}
            >
                <Select
                    onSelect={(value) => setFormData(prev => ({ ...prev, conversion_method: value }))}
                    style={{ width: '100%' }}
                    placeholder='Choose Unit Conversion Method'
                >
                    <Select.Option value="mul">Multipy by</Select.Option>
                    {/* <Select.Option value="div">Divided by</Select.Option> */}
                </Select>
            </Form.Item>

            <Text strong style={{ display: 'block', marginBottom: '24px' }}>
                {`1 ${unit.name} = ${formData.value} ${formData.name}`} {formData.conversion_method === 'div' && <sup>-1</sup>}
            </Text>

            <Button
                htmlType='submit'
                style={{ background: green.primary, color: '#fff' }}
            >
                Add Metric
            </Button>

            {(unit?.conversion_metric && Object.keys(unit?.conversion_metric).length !== 0) && <Col style={{ padding: '24px 0' }}>
                {
                    unit?.conversion_metric.map(metric => {

                        const { key, value, conversion_method } = metric
                        const splittedKey = key.split('_')
                        const firstUnit = unit.name
                        const secondUnit = splittedKey[2]

                        return (
                            <Tag
                                closable
                                onClose={() => setUnit(prev => ({
                                    ...prev,
                                    conversion_metric: prev.conversion_metric.filter(m => m.key !== key)
                                }))}
                                key={metric.key}
                                style={{ padding: '4px 12px' }}
                            >
                                {`1 ${firstUnit} = ${value} ${secondUnit}`} {conversion_method === 'div' && <sup>-1</sup>}
                            </Tag>
                        )
                    })
                }
            </Col>  }

            <Row style={{ marginTop: '24px' }}>
                <Button style={{ marginRight: '10px' }} htmlType='button' onClick={prev}>
                    previous
                </Button>

                <Button
                    htmlType='button'
                    onClick={handleFinishForm}
                    style={{ background: blue.primary, color: '#fff' }}
                >
                    Finish
                </Button>
            </Row>
        </Form >
    )
}