import React, { useState } from 'react'
import { Space, Card, Button, Tabs, Col } from 'antd'
import { red } from '@ant-design/colors';
import './DataAnalysis.css'
import { IngredientsAnalysisTable, PropertiesAnalysisTable, IngredientsRatioAnalysis, IngredientsCorrelation, PropertiesCorrelation, PropertiesHistogram, IngredientsHistogram, AllDataScatter, IngredientsOutlier, ProcessingOutlier, PropertiesOutlier, OriginalData, CleanData } from './renderers'
import DataAnalysisUpload from './DataAnalysisUpload'
import { ErrorBoundary } from '../common'
import Text from 'antd/lib/typography/Text';

const CatchError = ({ title, children }) => {
    return (
        <ErrorBoundary
            fallback={
                <Text
                    style={{ fontSize: '16px', color: red.primary }}
                >
                    Something went wrong with {title}
                </Text>
            }>
            {children}
        </ErrorBoundary>
    )
}

export const DataAnalysis = () => {
    const [data, setData] = useState(null)

    return (
        <>
            <Space className='data-analysis' direction="vertical" size="large" style={{ width: "100%", padding: "25px", background: '#f5f5f5', height: 'auto' }}>
                <Card title="Data Analysis" className='card' extra={
                    <Button
                        style={{ fontSize: '16px', visibility: data ? 'visible' : 'hidden', color: red.primary, borderColor: red.primary }}
                        onClick={() => setData(null)}
                    >
                        Close Analysis
                    </Button>
                }>
                    {data && (
                        <Tabs defaultActiveKey="0">
                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='Overview'
                                key='0'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title='Original Data'>
                                        <OriginalData
                                            data={data['original_data']}
                                        />
                                    </CatchError>

                                    <CatchError title='Clean Data'>
                                        <CleanData
                                            data={data['clean_data']}
                                        />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='Table Analysis'
                                key='1'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title='Ingredients Analysis Table 1'>
                                        <IngredientsAnalysisTable
                                            data={data['Ingredients Analysis Table 1']}
                                        />
                                    </CatchError>

                                    <CatchError title='Properties Analysis Table 1'>
                                        <PropertiesAnalysisTable
                                            data={data['Properties Analysis Table 1']}
                                        />
                                    </CatchError>

                                    <CatchError title="Ingredients Ratio Analysis">
                                        <IngredientsRatioAnalysis
                                            data={data['Ingredients Ratio Analysis']}
                                        />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='Correlation Analysis'
                                key='2'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title="Ingredients Correlation">
                                        <IngredientsCorrelation data={data['Ingredients Correlation']} />
                                    </CatchError>

                                    <CatchError title="Properties Correlation">
                                        <PropertiesCorrelation data={data['Properties Correlation']} />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='Histogram Analysis'
                                key='3'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title="Properties Histogram">
                                        <PropertiesHistogram data={data['Properties Histogram']} />
                                    </CatchError>

                                    <CatchError title="Ingredients Histogram">
                                        <IngredientsHistogram data={data['Ingredients Histogram']} />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='All Data Scatter'
                                key='4'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title="All Data Scatter">
                                        <AllDataScatter data={data['All Data Scatter']} />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                style={{ padding: '20px 0 0 0' }}
                                tab='Outlier Analysis'
                                key='5'
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px'
                                    }}
                                >
                                    <CatchError title="Ingredients Outlier">
                                        <IngredientsOutlier data={data['ingredients_outlier']} />
                                    </CatchError>

                                    <CatchError title="Processing Outlier">
                                        <ProcessingOutlier data={data['processing_outlier']} />
                                    </CatchError>

                                    <CatchError title="Properties Outlier">
                                        <PropertiesOutlier data={data['properties_outlier']} />
                                    </CatchError>
                                </Col>
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                    {!data && (
                        <DataAnalysisUpload setData={setData} />
                    )}
                </Card>
            </Space>
        </>
    )
}