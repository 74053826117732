import { useState, useContext } from 'react'
import { Menu, Button, Layout, Popconfirm } from 'antd'
import { useHistory, NavLink } from "react-router-dom"
import { FileProtectOutlined, GlobalOutlined, BarChartOutlined, AimOutlined, RadarChartOutlined, SecurityScanOutlined, DatabaseOutlined, PaperClipOutlined, BookOutlined, DeploymentUnitOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { CollapsedContext } from '../../App'


export const Navbar = ({ children }) => {

    const { push } = useHistory()

    const collapsedContext = useContext(CollapsedContext)

    const { Content, Sider } = Layout;

    const siderWidth = collapsedContext.collapsed ? 80 : 200
    const [current, setcurrent] = useState(window.location.pathname.split('/')[1])

    const handleMenu = (e) => {
        setcurrent(e.key)
    }

    const user_email = localStorage.getItem("user_email")

    const menuItems = [
        {
            label: 'Files Management',
            route: '/files',
            icon: <FileProtectOutlined />
        },
        {
            label: 'Companies',
            route: '/companies',
            icon: <GlobalOutlined />
        },
        {
            label: 'Metrics',
            route: '/metrics',
            icon: <BarChartOutlined />
        },
        {
            label: 'Data Analysis',
            route: '/data-analysis',
            icon: <RadarChartOutlined />
        },
        ["amit.borkar@polymerize.io", "neeraj.deshpande@polymerize.io", "vibhansh.gupta@polymerize.io"].includes(user_email) ?
            {
                label: 'ML',
                route: '/ml',
                icon: <AimOutlined />
            } : {},
        {
            label: 'Encrypt/Decrypt Data',
            route: '/encrypt-decrypt-data',
            icon: <SecurityScanOutlined />
        },
        {
            label: 'Units Conversion',
            route: '/units-conversion',
            icon: <DeploymentUnitOutlined />
        },
        {
            label: 'Database Management',
            route: '/database-management',
            icon: <DatabaseOutlined />
        },
        {
            label: "News Letter Subscription",
            route: "/news-letter-subscription",
            icon: <PaperClipOutlined />
        },
        {
            label: "Case Study Downloads",
            route: "/case-study",
            icon: <BookOutlined />
        },
        {
            label: "Metadata Uploading",
            route: "/meta-data-uploading",
            icon: <CloudUploadOutlined />
        }
    ]

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible={window.screen.width > 600} collapsed={window.screen.width > 600 ? collapsedContext.collapsed : true}
                onCollapse={() => collapsedContext.setCollapsed('setCollapsed')} style={{
                    overflow: 'auto',
                    height: '100%',
                    position: 'fixed',
                    scrollBehavior: 'smooth',
                    left: 0,
                    zIndex: 100
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                    <Menu onClick={handleMenu} mode="inline" theme="dark" selectedKeys={[current]}>
                        {menuItems.map(item => {
                            if (Object.keys(item).length) {
                                return (<Menu.Item icon={item.icon} key={item.route.split('/')[1]}>
                                    <NavLink to={item.route}>{item.label}</NavLink>
                                </Menu.Item>)
                            } else return null
                        })}
                    </Menu>
                    <Popconfirm title="Confirm Logout?" onConfirm={() => { localStorage.clear(); push('/') }}>
                        <Button style={{ marginTop: 'auto' }}
                            type="primary">Logout</Button>
                    </Popconfirm>
                </div>
            </Sider>
            <Layout className="site-layout">
                <Content style={window.screen.width > 600 ? { marginLeft: siderWidth, padding: '32px' } :
                    { marginLeft: 80, padding: '10px', overflow: 'auto' }
                }>
                    <div>{children}</div>
                </Content>
            </Layout>
        </Layout>
    )
}


