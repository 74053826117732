import React, { useState } from 'react'
import { Card, Row, Form, Input, Col, Select, Button, InputNumber, message, Spin } from "antd"
import { approveInverseRequestApi } from "../../services/apis"

const { Option } = Select

export const ApproveRequest = () => {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const approveInverseRequestSubmit = (values) => {
    setLoading(true)
    approveInverseRequestApi(values).then((res) => {
      if (res.data.status === "Success") {
        setLoading(false)
        message.success(`Inverse Request ${values.approval ? "Approved" : "Rejected"} !`)
      } else {
        message.error(`Error in ${values.approval ? "Approving" : "Rejecting"} request`)
      }
    }).catch((err) => {
      setLoading(false)
    })
  }


  return (
    <Spin spinning={loading}>
      <Card>
        <Form onFinish={approveInverseRequestSubmit} form={form}>
          <Row justify="space-between">
            <Col span={6}>
              <Form.Item label="Version" name="version" rules={[{ required: true }]}>
                <InputNumber style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Company Id" name="company_id" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Approval" name="approval" rules={[{ required: true }]}>
                <Select>
                  <Option value={true}>{"True"}</Option>
                  <Option value={false}>{"False"}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="User Id" name="user_id" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Message" name="message" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Row justify="end" gutter={20}>
            <Col>
              <Button onClick={() => form.resetFields()}>Clear</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  )
}

