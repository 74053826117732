import React, { useEffect, useState } from "react";
import { Space } from 'antd';
import { getUnitConversionList } from '../../services/apis'
import { ErrorBoundary } from '../common';
import Text from 'antd/lib/typography/Text';
import { red } from '@ant-design/colors';
import { UnitsConversion } from './UnitsConversion'

export const UnitsConversionWrapper = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)

    async function fetchUnitsList() {
        try {
            const data = await getUnitConversionList({ "category": "" });
            if (data.data.result.status === 'Success') setData(data.data.result.data)
            else setError(data.data.result.message)
        } catch (err) {
            setError(err.toString())
        }
    }

    useEffect(() => {
        fetchUnitsList()
    }, [])

    return (
        <Space
            className='data-analysis'
            direction="vertical"
            size="large"
            style={{ width: "100%", padding: "25px", background: '#f5f5f5', height: 'auto' }}
        >
            {!!!error && (
                <ErrorBoundary
                    fallback={
                        <Text
                            style={{ fontSize: '16px', color: red.primary }}
                        >
                            Something went wrong, please try again later.
                        </Text>
                    }
                >
                    <UnitsConversion data={data} refetch={fetchUnitsList} />
                </ErrorBoundary>
            )}

            {!!error && (
                <Text
                    style={{ fontSize: '16px', color: red.primary }}
                >
                    {error}
                </Text>
            )}
        </Space>
    );
};
