import React, { useMemo } from "react";
import Highcharts from "highcharts"
import HighchartsHeatmap from "highcharts/modules/heatmap"
import { RendererWrapper, CustomHighChart } from '.'

HighchartsHeatmap(Highcharts)

function getFontSize(arrLen) {
    if (arrLen < 60) return '13px'
    if (arrLen < 80) return '11px'
    if (arrLen < 100) return '10px'
    return '8px'
}

export const PropertiesCorrelation = ({ data }) => {
    const highChartsPlotsData = useMemo(() => {
        const correlation = data
        const iterator = Object.keys(correlation)[0]
        const x = Object.keys(correlation)
        const y = Object.keys(correlation[iterator])
        const array = []
        x.forEach((x_res, x_index) => {
            y.forEach((y_res, y_index) => {
                array.push([x_index, y_index, correlation[x_res][y_res]])
            })
        })

        return {
            chart: {
                type: 'heatmap',
                height: 400,
                plotBorderWidth: 1,
            },
            xAxis: {
                categories: x,
                title: null
            },
            yAxis: {
                categories: y,
                title: null,
                reversed: true
            },
            colorAxis: {
                min: -1,
                max: 1,
                stops: [
                    [0, '#ac261f'],
                    [0.125, '#f46d43'],
                    [0.25, '#fdae61'],
                    [0.375, '#fee08b'],
                    [0.5, '#ffffbf'],
                    [0.625, '#d9ef8b'],
                    [0.75, '#a6d96a'],
                    [0.875, '#66bd63'],
                    [1, '#1a9850'],
                ],
            },
            title: {
                text: ""
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },
            series: [{
                type: 'heatmap',
                borderWidth: 1,
                borderColor: '#111',
                data: array,
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    style: {
                        fontSize: getFontSize(array.length),
                    }
                },
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 200
                    },
                }]
            },
            tooltip: {
                formatter: function () {
                    const point = this.point
                    const series = point.series
                    const xVal = series['xAxis'].categories[point['x']]
                    const yVal = series['yAxis'].categories[point['y']]
                    return `${point.value}, ${yVal}, ${xVal}`
                }
            },
        }
    }, [data])

    return (
        <RendererWrapper heading='PROPERTIES CORRELATION'>
            <CustomHighChart
                highcharts={Highcharts}
                options={highChartsPlotsData}
            />
        </RendererWrapper>
    )
};
