import React from "react";
import { Modal } from 'antd';

export const ViewUnitConversionModal = ({ data, closeModal }) => {
    return (
        <Modal
            title="Conversion Metrics"
            footer={null}
            onCancel={closeModal}
            visible={!!data}
        >
            {data && data.map((metric, i) => {
                console.log(metric)
                return (
                    <div key={i}>{metric.value} {metric.type === 'div' && <sup>-1</sup>}</div>
                )
            })}
        </Modal>
    );
};
