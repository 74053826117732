import React from "react";
import { Card, Space, Tabs } from 'antd';
// import { DatabaseBackup } from './DatabaseBackup'
import { DatabaseRestore } from './DatabaseRestore'

export const DatabaseManagement = () => {
    return (
      <Space
        className="data-analysis"
        direction="vertical"
        size="large"
        style={{
          width: "100%",
          padding: "25px",
          background: "#f5f5f5",
          height: "auto",
        }}
      >
        <Card title="Data Management" className="card">
          <Tabs defaultActiveKey="backup">
            {/* <Tabs.TabPane
                        style={{ padding: '20px 0 0 0' }}
                        tab='Database Backup'
                        key='backup'
                    >
                        <DatabaseBackup />
                    </Tabs.TabPane> */}

            <Tabs.TabPane
              style={{ padding: "20px 0 0 0" }}
              tab="Database Sync"
              key="restore"
            >
              <DatabaseRestore />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Space>
    );
};
