import React, { useState, useEffect, useMemo } from 'react'
import { FileUploadForm } from './FileUpload'
import "antd/dist/antd.css";
import { Row, Col, Space, Card, Button, Table, Popconfirm, notification, message, Modal, Descriptions, Input } from 'antd'
import { useHistory } from "react-router-dom"
import { tableData } from '../../utils/decorators'
import { approveFilesApi, getFileListApi, removeFileApi } from '../../services/apis'


export const FilesManagement = () => {

    const { push } = useHistory()
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)
    const [filename, setFileName] = useState('')
    const [editRecord, setEditRecord] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState({})
    const [filteredFiles, setFilteredFiles] = useState([])
    const [render, setRender] = useState(0)

    useEffect(() => {
        setLoading(true)
        getFileListApi().then((res) => {
            if (res.status === 200) {
                setLoading(false)
                setFiles(res.data.result.data.files)
                setFilteredFiles(res.data.result.data.files)
            }
            else {
                message.error("Not able to access files")
            }
        }).catch((err) => {
            localStorage.clear()
            push('/')
        }
        )
    }, [push, render])


    const approveFiles = (filename) => {
        setFileName(filename)
        setApproveLoading(true)
        approveFilesApi(filename).then((res) => {
            setApproveLoading(false)
            notification.success({
                message: 'File Approved',
                placement: 'bottomRight'
            })
            setRender(prevState => prevState + 1)
        }).catch((err) => setApproveLoading(false))
    }

    const removeFile = (filename) => {
        setFileName(filename)
        setRemoveLoading(true)
        removeFileApi(filename).then((res) => {
            setRemoveLoading(false)
            notification.success({
                message: 'File Removed',
                placement: 'bottomRight'
            })
            setRender(prevState => prevState + 1)
        }).catch((err) => setRemoveLoading(false))
    }

    const searchFiles = (e) => {
        const value = e.target.value
        value.trim().length !== 0 ?
            setFilteredFiles(files.filter((res) => res.filename.toLowerCase().includes(value.trim().toLowerCase()))) :
            setFilteredFiles(files)
    }


    const tableColumns = useMemo(() => {
        const columns = []
        let row = Object.keys(files[0] || {})
            .filter((res) => res !== 'updated' && res !== 's3_bucket' && res !== 's3_filekey' && res !== 'email')
        for (let i in row) {
            columns.push({
                title: row[i].charAt(0).toUpperCase() + row[i].slice(1),
                dataIndex: row[i],
                key: row[i],
                align: 'center',
                ...((row[i] === 'user_name' || row[i] === 'user_email' || row[i] === 'use_case') &&
                    { filters: [...new Set(files.map(key => key[row[i]]))].map((res) => ({ text: res, value: res })) }),
                onFilter: (value, record) => record[row[i]] === value
            })
        }
        columns.push({
            title: 'Actions',
            key: 'operations',
            align: 'center',
            ...(window.screen.width > 1000 && { fixed: 'right' }),
            render: (text, record) => (
                <Space size="middle">
                    {record.status !== 'approved' &&
                        <Popconfirm title="Sure to approve?" onConfirm={() => approveFiles(record.filename)}>
                            <Button loading={filename === record.filename && approveLoading}>Approve</Button>
                        </Popconfirm>
                    }
                    <Popconfirm title="Sure to remove?" onConfirm={() => removeFile(record.filename)}>
                        <Button loading={filename === record.filename && removeLoading}>Remove</Button>
                    </Popconfirm>
                    <Button onClick={() => { window.scrollTo(0, 0); setEditRecord(record) }} type="primary">Edit</Button>
                </Space>
            ),
        })
        return columns
    }, [files, approveLoading, removeLoading, filename])

    const data = tableData(filteredFiles, 'files')

    return (
        <>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Row justify="center" align="top" >
                    <Col span={22} style={{ marginBottom: 50 }}>
                        <FileUploadForm editRecord={editRecord} setEditRecord={setEditRecord} setRender={setRender} />
                    </Col>
                    <Col span={22} style={{ overflow: 'auto' }}>
                        <Card title="Uploaded Files" extra={<Input.Search placeholder="Search Files" onChange={searchFiles} allowClear />}>
                            <Space size="large" direction="vertical" style={{ width: '100%', overflow: 'auto' }}>
                                <Table rowSelection={{
                                    type: 'radio', fixed: true,
                                    onSelect: (record, selected, selectedRows, nativeEvent) => {
                                        setIsModalVisible(true)
                                        setModalData(selectedRows[0])
                                    }
                                }}
                                    bordered loading={loading} columns={tableColumns} dataSource={data} pagination={{ pageSize: 10 }} />
                            </Space>
                        </Card>
                    </Col>
                </Row>
                <Modal title="File Details" centered visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)} width={1000} footer={""}>
                    <Descriptions column={{ xl: 2, sm: 1, xs: 1 }} bordered>
                        {Object.entries(modalData).map(([key, value]) => (
                            <Descriptions.Item label={key}>{value}</Descriptions.Item>
                        ))}
                    </Descriptions>
                </Modal>
            </Space>
        </>
    )
}

