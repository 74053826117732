import './styles.css'
import React, { useState } from "react";
import { Button, Card, Input, Space, Form, Select, message } from 'antd';
import { useCompanies, useMetadataUploading } from '../../services/hooks';

export const MetadataUploading = () => {
    const [form] = Form.useForm()
    const { loading, uploadMetadata } = useMetadataUploading()
    const { data: companies } = useCompanies()
    const [file, setFile] = useState(null)

    function handleUpload(values) {
        if (!file) return message.error('Please select a file')
        if (file.type !== 'application/json') return message.error('Please select a json file')

        uploadMetadata({
            payload: {
                version: values.versions,
                env: values.environment,
                company_id: values.company
            },
            metadata: file,
        })
    }

    return (
        <Space
            className='metadata-uploading'
            direction="vertical"
            size="large"
            style={{ width: "100%", padding: "25px", background: '#f5f5f5', height: 'auto' }}
        >
            <Card title="Metadata Uploading" className='card'>
                <Form name="metadata-form" layout='vertical' form={form} onFinish={handleUpload}>
                    <Form.Item
                        name='file'
                        label="Metadata File"
                        rules={[{ required: true }]}
                    >
                        <Input
                            type='file'
                            accept='.json'
                            onChange={(e) => {
                                setFile(e.target.files[0])
                            }}
                            style={{ maxWidth: '300px' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='company'
                        label="Company"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Company">
                            {(companies ?? []).map(company => {
                                return (
                                    <Select.Option
                                        value={company.company_id}
                                        key={company.company_id}
                                    >
                                        {company.company_name}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='environment'
                        label="Environment"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Environment">
                            {(["SIT", "UAT", "PROD"]).map(env => {
                                return (
                                    <Select.Option
                                        value={env}
                                        key={env}
                                    >
                                        {env}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='versions'
                        label="Versions"
                        required
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value && value.length > 0) return Promise.resolve();
                                    return Promise.reject('Please select at least one version');
                                },
                            },
                        ]}
                    >
                        <Select
                            notFoundContent="Please enter a version and hit enter!"
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Versions"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ width: '300px' }}
                            type='primary'
                            disabled={loading}
                            htmlType='submit'
                        >
                            {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Space>
    )
};
