import React, { useEffect, useMemo, useState } from "react";
import { Card, Space, Table, Button, message, Popconfirm } from 'antd';
import { blue, red } from '@ant-design/colors'
import { ViewUnitConversionModal, AddUnitModal, UpdateUnitModal } from './modals'
import { EditIcon, DeleteIcon } from '../icons'
import { deleteUnitConversion } from '../../services/apis'

async function deleteUnit(id, refetch) {
    try {
        const data = await deleteUnitConversion({ unit_id: id })
        if (data.data.result) {
            refetch()
            message.success('Unit deleted successfully')
        }
        else message.error('Something went wrong while deleting the unit, please try again later')
    } catch (err) {
        console.error('ERROR DELETING UNIT', err)
        message.error('Something went wrong while deleting the unit, please try again later')
    }
}

export const UnitsConversion = ({ data, refetch }) => {
    const [viewConversionId, setViewConversionId] = useState(null)
    const [isAddNewUnitModal, setIsAddNewUnitModal] = useState(false)
    const [updateMetricId, setUpdateMerticId] = useState(null)
    const url = window.location.href;
    const pathParts = url.split('/');

    const lastParameter = pathParts[pathParts.length - 1];

    useEffect(() => {
        if (lastParameter) {
            setUpdateMerticId(lastParameter)
        }
    }, [lastParameter]);

    // TO VIEW ANY METRIC UNIT CONVERSION
    const viewMetricData = useMemo(() => {
        if (viewConversionId) {
            const metric = data.find(item => item.unit_id === viewConversionId)
            if (metric) {
                return Object.keys(metric.conversion_metric || {}).map(key => {
                    const splittedKey = key.split('_')
                    const conversionMetric = metric.conversion_metric[key]
                    const conversionTypes = Object.keys(conversionMetric)
                    return conversionTypes.map(type=>{
                        return {
                            value: `1 ${splittedKey[0]} = ${conversionMetric[type][1]} ${splittedKey[2]}`,
                            type: type
                        }
                    })[0]
                        
                    
                })
            }
        }
        return null
    }, [viewConversionId, data])

    // TO UPDATE ANY METRIC UNIT CONVERSION
    const updateMetricData = useMemo(() => {
        if (!updateMetricId) return null
        const metric = data.find(item => item.unit_id === updateMetricId)
        if (!metric) return null

        return {
            unit_id: metric.unit_id,
            name: metric.name,
            category: metric.category,
            alternative_values: metric.alternative_values,
            conversion_metric: metric?.conversion_metric ? Object.keys(metric.conversion_metric).map(key => {
                const conversionMetric = metric.conversion_metric[key]
                const conversionType = conversionMetric['div'] ? 'div' : 'mul'

                return {
                    key,
                    value: metric.conversion_metric[key][conversionType][1],
                    conversion_method: conversionType,
                }
            }) : {}
        }
    }, [updateMetricId, data])

    const columns = useMemo(() => [
        {
            title: '',
            dataIndex: 'unit_id',
            key: 'unit_id',
            render: () => null
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Alternative Values',
            dataIndex: 'alternative_values',
            key: 'alternative_values',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Conversion Metrics',
            dataIndex: 'conversion_metric',
            key: 'conversion_metric',
            render: (metric, record) => {
                return (
                    <span
                        style={{ color: blue.primary, cursor: 'pointer' }}
                        onClick={() =>{
                            setViewConversionId(record.unit_id)}}
                    >
                        {metric}
                    </span>
                )
            }
        },
        {
            title: '',
            dataIndex: 'updatebtn',
            key: 'updatebtn',
            render: (_, record) => {
                return (
                    <Button
                        style={{ padding: 0, height: 'max-content', border: 'none' }}
                        onClick={() => setUpdateMerticId(record.unit_id)}
                    >
                        <EditIcon />
                    </Button>
                )
            }
        },
        {
            title: '',
            dataIndex: 'deletebtn',
            key: 'deletebtn',
            render: (_, record) => {
                return (
                    <Popconfirm title="Are you sure you want to delete this Unit?" onConfirm={() => deleteUnit(record.unit_id, refetch)}>
                        <Button
                            style={{ padding: 0, height: 'max-content', border: 'none', color: red.primary }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Popconfirm>
                )
            }
        }
    ], [setUpdateMerticId, refetch])

    const dataSource = useMemo(() => {
        return data.map(item => {
            return ({
            key: item.unit_id,
            unit_id: item.unit_id,
            name: item.name,
            alternative_values: item.alternative_values.join(', '),
            category: item.category,
            company: item.company,
            conversion_metric: 'Check List',
            updatebtn: '',
            deletebtn: ''
        })})
    }, [data])

    return (
        <Space
            className='data-analysis'
            direction="vertical"
            size="large"
            style={{ width: "100%", padding: "25px", background: '#f5f5f5', height: 'auto' }}
        >
            <Card
                title="Units Conversion"
                className='card'
                extra={
                    <Button
                        style={{ fontSize: '16px', background: blue.primary, color: '#fff', padding: '0 20px', height: '40px' }}
                        onClick={() => setIsAddNewUnitModal(true)}
                    >
                        Add New Unit
                    </Button>
                }
            >
                <Table columns={columns} dataSource={dataSource} pagination={false} />
            </Card>

            {/* VIEW UNIT CONVERSION METRIC MODAL */}
            <ViewUnitConversionModal
                data={viewMetricData}
                closeModal={() => setViewConversionId(null)}
            />

            {/* ADD NEW UNIT CONVERSION METRIC MODAL */}
            <AddUnitModal
                refetch={refetch}
                isAddNewUnitModal={isAddNewUnitModal}
                closeModal={() => setIsAddNewUnitModal(false)}
            />

            {/* UPDATE UNIT CONVERSION METRIC MODAL */}
            <UpdateUnitModal
                refetch={refetch}
                isVisible={!!updateMetricData}
                closeModal={() => setUpdateMerticId(null)}
                data={updateMetricData}
            />
        </Space>
    );
};
