
import React, { useState, useEffect } from 'react'
import { Space, Card, Row, Form, Input, Col, Select, Button, InputNumber, message, Spin, Collapse, Typography } from "antd"
import { ModelNames } from './ModelNames'
import { deployDevModelsApi } from "../../services/apis"
import { blue } from "@ant-design/colors"

const { Option } = Select
const { Panel } = Collapse
const { Paragraph } = Typography
const { TextArea } = Input


export const DeployModel = () => {

  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      metric: "mean_absolute_percentage_error",
      metric_threshold: 30,
      top_n_models: 4,
      metric_reverse: true,
      input_type: ["ingredients"],
      kfold: 5,
      source: "MONGO",
      is_custom: false
    })
  }, [form])

  const deployModelFormSubmit = (values) => {
    const model_names = values.model_names.reduce((array, element) => ({
      ...array, [element.propertyName]: element.propertyValue
    }), {})
    const { company_id, comments, objective, model_metadata, metric, metric_threshold, top_n_models, metric_reverse, input_type,
      output_type, x_cols, drop_cols, test_split, kfold, source, version, is_custom } = values

    const payload = {
      company_id,
      comments,
      objective,
      model_metadata: JSON.parse(model_metadata),
      model_configs: {
        metric,
        metric_threshold,
        top_n_models,
        metric_reverse,
        input_type,
        output_type,
      },
      data_configs: {
        x_cols: x_cols.split(","),
        drop_cols: drop_cols.split(","),
        test_split,
        kfold,
        source
      },
      version,
      model_names: model_names,
      is_custom
    }
    setLoading(true)
    deployDevModelsApi(payload).then((res) => {
      if (res.data.status === "Success") {
        setResponseData(res.data.data)
        setLoading(false)
        message.success("Model Data Generated!")
      } else {
        message.error('Error in deploying model')
      }
    }).catch((err) => {
      setLoading(false)
    })
  }


  return (
    <Spin spinning={loading}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card>
          <Form name="dynamic_form_item" onFinish={deployModelFormSubmit} form={form}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Row justify="space-between">
                <Col>
                  <Form.Item label="Company Id" name="company_id" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Objective" name="objective" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Comments" name="comments" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Model Metadata" name="model_metadata" rules={[{ required: true },
              () => ({
                validator(_, value) {
                  try {
                    if (value) {
                      JSON.parse(value)
                    }
                    return Promise.resolve()
                  } catch (error) {
                    return Promise.reject(new Error(`Invalid JSON format - ${error}`))
                  }
                },
              }),
              ]}>
                <TextArea rows={8} />
              </Form.Item>

              <Card title="Model Configs" type="inner">
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                  <Row justify="space-around">
                    <Col span={8}>
                      <Form.Item label="Input Type" name="input_type" rules={[{ required: true }]}>
                        <Select mode="multiple" allowClear defaultValue={["ingredients"]}>
                          <Option value={"ingredients"}>{"Ingredients"}</Option>
                          <Option value={"processing"}>{"Processing"}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Metric" name="metric" rules={[{ required: true }]}>
                        <Input defaultValue={"mean_absolute_percentage_error"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={6}>
                      <Form.Item label="Top n models" name="top_n_models" rules={[{ required: true }]}>
                        <InputNumber defaultValue={4} type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Metric Threshold" name="metric_threshold" rules={[{ required: true }]}>
                        <InputNumber defaultValue={30} type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Metric reverse" name="metric_reverse" rules={[{ required: true }]}>
                        <Select>
                          <Option value={true}>{"True"}</Option>
                          <Option value={false}>{"False"}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Card>
              <Card title="Data Configs" type="inner">
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                  <Row justify="space-between">
                    <Col span={4}>
                      <Form.Item label="Version" name="version" rules={[{ required: true }]}>
                        <InputNumber type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Test Split" name="test_split" rules={[{ required: true }]}>
                        <InputNumber type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="K fold" name="kfold" rules={[{ required: true }]}>
                        <InputNumber defaultValue={5} type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Is Custom" name="is_custom" rules={[{ required: true }]}>
                        <Select>
                          <Option value={true}>{"True"}</Option>
                          <Option value={false}>{"False"}</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Source" name="source" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-around">
                    <Col span={10}>
                      <Form.Item label="X Columns" name="x_cols" rules={[{ required: true }]}>
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item label="Drop Columns" name="drop_cols" rules={[{ required: true }]}>
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Card>
              <ModelNames />
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit">Deploy Model</Button>
                </Col>
              </Row>
            </Space>
          </Form>
        </Card>
        {Object.keys(responseData || {}).length ?
          <Collapse defaultActiveKey={["model-response"]}>
            <Panel header="Model Data Response" key="model-response">
              <Card>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                  <Card title="Data Configs" bodyStyle={{ background: blue[0] }} headStyle={{ background: blue[1] }}>
                    <Paragraph copyable>{JSON.stringify(responseData?.data_configs || {}, null, 5)}</Paragraph>
                  </Card>
                  <Card title="Model Configs" bodyStyle={{ background: blue[0] }} headStyle={{ background: blue[1] }}>
                    <Paragraph copyable>{JSON.stringify(responseData?.model_configs || {}, null, 5)}</Paragraph>
                  </Card>
                  <Card title="Model Api Configs" bodyStyle={{ background: blue[0] }} headStyle={{ background: blue[1] }}>
                    <Paragraph copyable>{JSON.stringify(responseData?.model_api_configs || {}, null, 5)}</Paragraph>
                  </Card>
                </Space>
              </Card>
            </Panel>
          </Collapse>
          : null}
      </Space>
    </Spin>
  )
}
