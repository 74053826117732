import { message } from 'antd'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_URL } from '../@constants'
import { INTERNAL_SERVER_ERROR } from '../@constants/messages'

export const useMetadataUploading = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    async function uploadMetadata(input) {
        setLoading(true)
        setError(null)

        const { metadata, payload } = input
        const formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        formData.append('metadata', metadata)

        try {
            const response = await axios.post(`${API_URL}/upload_metadata`, formData, {
                headers: { token: localStorage.getItem('token') }
            })
            const result = response.data.result

            if (response.status === 400) {
                setError('Json file data is not valid!')
                setData(null)
                message.error('Json file data is not valid!')
                return
            }
            if (response.status === 401) {
                setError('Password is not correct!')
                setData(null)
                message.error('Password is not correct!')
                return
            }
            if (response.status === 403) {
                setError('There is a conflict with the ingredient name!')
                setData(null)
                message.error('There is a conflict with the ingredient name!')
                return
            }

            if (response.status === 404) {
                setError('Environement is not valid!')
                setData(null)
                message.error('Environement is not valid!')
                return
            }
            if (response.status === 201) {
                setData(result)
                message.success(result.message)
                return
            }

            setError(INTERNAL_SERVER_ERROR)
            message.error(INTERNAL_SERVER_ERROR)
            setData(null)
        } catch (err) {
            setError(INTERNAL_SERVER_ERROR)
            message.error(INTERNAL_SERVER_ERROR)
            setData(null)
        } finally {
            setLoading(false)
        }
    }

    return { data, loading, error, uploadMetadata }
}

export const useCompanies = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    async function fetchCompanies() {
        setLoading(true)
        setError(null)

        try {
            const result = await axios.post(`${API_URL}/companies_list`, null,
                { headers: { token: localStorage.getItem('token') } })
            if (result.status === 200) {
                setData(result.data.result.result.data.companies)
            } else {
                setError(INTERNAL_SERVER_ERROR)
                setData(null)
            }
        } catch (err) {
            console.log({ err }, 'WHEN FETCHING COMPANIES');
            setError(INTERNAL_SERVER_ERROR)
            setData(null)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCompanies()
    }, [])


    return { data, loading, error, refetch: fetchCompanies }
}