import { useEffect, useState, useMemo } from 'react'
import { Space, Table, Card, Row, Col, message, List, Descriptions, notification, Popconfirm, Button } from 'antd'
import { getCompaniesListApi, getUsersListApi, accessControlApi } from '../../services/apis'
import { useHistory } from "react-router-dom"
import { tableData } from '../../utils/decorators'
import { geekblue } from '@ant-design/colors';


export const Companies = () => {

    const { push } = useHistory()
    const [companyId, setCompanyId] = useState("")
    const [companies, setCompanies] = useState([])
    const [companiesLoading, setCompaniesLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [usersLoading, setUsersLoading] = useState(false)

    useEffect(() => {
        setCompaniesLoading(true)
        getCompaniesListApi().then((res) => {
            if (res.status === 200) {
                setCompaniesLoading(false)
                setCompanies(res.data.result.result.data.companies)
                setCompanyId(res.data.result.result.data.companies[0].company_id)
                setUsersLoading(true)
                getUsersListApi(res.data.result.result.data.companies[0].company_id).then((res) => {
                    if (res.status === 200) {
                        setUsersLoading(false)
                        setUsers(res.data.result.data.users)
                    } else {
                        message.error('Not able to access usesr')
                    }
                }).catch(err => setUsersLoading(false))
            } else {
                message.error('Not able to access companies')
            }
        }).catch((err) => {
            console.log('ERROR IN FETCHING COMPANIES', err);
        })
    }, [push])

    const getUsers = (company_id) => {
        setCompanyId(company_id)
        setUsersLoading(true)
        getUsersListApi(company_id).then((res) => {
            if (res.status === 200) {
                setUsersLoading(false)
                setUsers(res.data.result.data.users)
            } else {
                message.error('Not able to access usesr')
            }
        }).catch(err => setUsersLoading(false))
    }

    const changeUserAccess = (record) => {
        const action = record.user_access === 'true' ? 'revoke' : 'grant'
        setUsersLoading(true)
        accessControlApi({
            user_email: localStorage.getItem('user_email'),
            target_email: record.user_email,
            action
        }).then((res) => {
            if (res.status === 200) {
                notification.success({
                    message: 'User Access Updated',
                    placement: 'bottomRight'
                })
                getUsersListApi(record.company_id).then((res) => {
                    setUsersLoading(false)
                    setUsers(res.data.result.data.users)
                }).catch(err => setUsersLoading(false))
            } else {
                message.error('Not Authorized to edit user access')
            }
        }).catch((err) => setUsersLoading(false))
    }

    const tableColumns = useMemo(() => {
        const columns = []
        columns.push({
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            align: 'center',
            ...(window.screen.width > 1000 && { fixed: 'left' }),
        })
        const row = Object.keys(users?.[0] || {}).filter((res) => res !== 'updated' && res !== 'user_id' && res !== 'company')
        for (let i in row) {
            columns.push({
                title: row[i].charAt(0).toUpperCase() + row[i].slice(1),
                dataIndex: row[i],
                key: row[i],
                align: 'center'
            })
        }
        localStorage.getItem('access_level') === '0' && columns.push({
            title: 'Actions',
            key: 'operations',
            align: 'center',
            ...(window.screen.width > 1000 && { fixed: 'right' }),
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title={`Sure to ${record.user_access === 'true' ? 'Revoke' : 'Grant'} access ?`}
                        onConfirm={() => changeUserAccess(record)}>
                        <Button type="dashed">
                            {record.user_access === 'true' ? 'Revoke' : 'Grant'}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        })
        return columns
    }, [users])

    const data = tableData(users, 'users')

    return (
        <Space direction="vertical" size="large" style={{ width: "100%", overflow: 'auto' }}>
            <Row gutter={16} justify="center" align="top" >
                <Col xl={15} style={{ marginBottom: 50 }}>
                    <Card title={`Users (Count - ${users?.length || 0})`} >
                        <Space size="large" direction="vertical" style={{ width: '100%', overflow: 'auto' }}>
                            <Table size="middle" columns={tableColumns} dataSource={data} loading={usersLoading}
                                bordered pagination={{ pageSize: 10 }} />
                        </Space>
                    </Card>
                </Col>
                <Col style={{ minWidth: 200, overflow: 'auto' }}>
                    <Card title={`Companies List (Count - ${companies?.length || 0})`} >
                        <Space direction="vertical" size="large" style={{ width: "100%", overflow: 'auto' }}>
                            <List style={{ minWidth: 500 }}
                                loading={companiesLoading}
                                bordered
                                dataSource={companies}
                                pagination={{ pageSize: 5, responsive: true }}
                                renderItem={item => (
                                    <List.Item style={item.company_id === companyId ? { minWidth: 400, cursor: 'pointer', background: geekblue[1] }
                                        : { minWidth: 500, cursor: 'pointer', overflow: 'auto' }}
                                        onClick={() => getUsers(item.company_id)}>
                                        <Descriptions bordered column={1} style={{ background: 'white', minWidth: 100 }}>
                                            {Object.entries(item || {}).map(([key, value]) => (
                                                key === 'active' ? <Descriptions.Item label={key.charAt(0).toUpperCase() + key.slice(1)}>{value?.toString()}
                                                </Descriptions.Item> :
                                                    <Descriptions.Item label={key?.split('_').map(res => res.charAt(0).toUpperCase() + res.slice(1) + ' ')}>{value}</Descriptions.Item>
                                            ))}
                                        </Descriptions>
                                    </List.Item>
                                )}
                            />
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Space>
    )
}
