import { Card, message, PageHeader, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react'
import { fetchNewsLetterList } from '../../services/apis';

export const NewsLetterSubscription = () => {
    const [loading, setLoading] = useState(false)
    const [newsLetter, setNewsLetter] = useState([])

    const newsLetterColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <strong>{text || "-"}</strong>

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            render: (text) => <Text>{text || "-"}</Text>
        },
        {
            title: 'Created On',
            dataIndex: 'created',
            key: 'created',
            render: (createdOn) => <Text>{new Date(createdOn).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) || "-"}</Text>
        },
    ]

    useEffect(() => {
        setLoading(true)
        fetchNewsLetterList().then((res) => {
            if (res.status === 200) {
                setLoading(false)
                setNewsLetter(res.data.result.data)
            } else {
                message.error('Not able to Fetch News Letter')
            }
        }).catch((err) => {
            console.log('ERROR IN FETCHING News Letter', err);
        })
    }, [])

    return (
        <div>
            <PageHeader style={{ margin: "0 0 1.5rem 0", padding: 0 }}>
                <Card>
                    <Title level={5}>News Letter Subscription</Title>
                </Card>
            </PageHeader>
            <Table dataSource={newsLetter} columns={newsLetterColumns} loading={loading} bordered />
        </div>
    )
}
