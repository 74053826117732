import React, { useState } from "react";
import { message, Modal, Steps } from "antd";
import { NameAndCategoryForm } from './UnitConversionNameNCategoryForm'
import { AlternateValuesForm } from './UnitConversionAlternateValuesForm'
import { ConversionMetricForm } from './UnitConversionMetricsForm'
import { addNewUnitConversion } from '../../../../services/apis'

const steps = [
    {
        title: 'Add Name and Category',
        content: NameAndCategoryForm,
    },
    {
        title: 'Add Alternate Value',
        content: AlternateValuesForm,
    },
    {
        title: 'Add Conversion Metric',
        content: ConversionMetricForm,
    },
];

const initialData = {
    name: '',
    category: '',
    alternative_values: [],
    conversion_metric: []
}

export const AddUnitModal = ({ isAddNewUnitModal, closeModal, refetch }) => {
    const [unit, setUnit] = useState(initialData)
    const [currentStep, setCurrentStep] = useState(0)

    const next = () => setCurrentStep(currentStep + 1);
    const prev = () => setCurrentStep(currentStep - 1);
    const CurrentStep = steps[currentStep].content

    async function handleAddUnit() {
        const conversion_metric = {}
        unit.conversion_metric.forEach(metric => {
            conversion_metric[metric.key] = {
                [metric.conversion_method]: [
                    { "var": 0 },
                    Number(metric.value)
                ]
            }
        })

        const payload = {
            name: unit.name,
            category: unit.category,
            alternative_values: unit.alternative_values,
            conversion_metric
        }

        try {
            const data = await addNewUnitConversion(payload)
            if (data.data.result.status === 'Success') {
                refetch()
                message.success('Unit successfully added')
            }
            else message.error(data.data.result.message)
        } catch (err) {
            console.log('ERROR CREATING NEW UNIT', err);
            message.error('There was an error creating the new unit')
        }

        setUnit(initialData)
        closeModal()
    }

    return (
        <Modal
            title="Add New Unit"
            footer={null}
            width={900}
            onCancel={closeModal}
            visible={isAddNewUnitModal}
        >
            <Steps current={currentStep}>
                {steps.map(step => {
                    return <Steps.Step key={step.title} title={step.title} />
                })}
            </Steps>
            <div style={{ marginTop: '32px' }}>
                <CurrentStep
                    setUnit={setUnit}
                    next={next}
                    prev={prev}
                    finish={handleAddUnit}
                    unit={unit}
                />
            </div>
        </Modal >
    )
};