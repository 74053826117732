import { Router, Switch, Route, Link } from "react-router-dom"
import { FilesManagement } from './components/FilesManagement/FilesManagement'
import { DataEditor } from './components/FilesManagement/DataEditor'
import { Companies } from './components/Companies/Companies'
import { DataAnalysis } from './components/DataAnalysis/DataAnalysis'
import { Metrics } from './components/Metrics/Metrics'
import { Navbar } from './components/Navbar/Navbar'
import { Login } from './components/Login/Login'
import { ML } from './components/ML/ML'
import { createBrowserHistory } from "history"
import { Result } from 'antd'
import { DataSecurity } from "./components/DataSecurity/DataSecurity"
import { UnitsConversionWrapper as UnitsConversion } from './components/UnitsConversion'
import { DatabaseManagement } from './components/DatabaseManagement'
import { NewsLetterSubscription } from "./components/NewsLetter/NewsLetterSubscription"
import { CaseStudy } from "./components/CaseStudy/CaseStudy"
import { MetadataUploading } from './components/MetadataUploading/MetadataUploading'

export const history = createBrowserHistory()

const NoMatch = () => (
    <Result
        style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}
        status="404"
        title="Page Not Available"
        subTitle=""
        extra={<Link type="primary" to="/files">Back Home</Link>}
    />
)

export const routes = [
    {
        path: "/",
        component: Login,
        exact: true,
        protectedRoute: false,
    },
    {
        path: "/files",
        component: FilesManagement,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/companies",
        component: Companies,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/metrics",
        component: Metrics,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/data-editor",
        component: DataEditor,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/ml",
        component: ML,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/data-analysis",
        component: DataAnalysis,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/encrypt-decrypt-data',
        component: DataSecurity,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/units-conversion",
        component: UnitsConversion,
        exact: true,
        protectedRoute: true,
    },
    {
        path: "/units-conversion/:slug",
        component: UnitsConversion,
        exact: true,
        protectedRoute: true,  
    },
    {
        path: '/database-management',
        component: DatabaseManagement,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/news-letter-subscription',
        component: NewsLetterSubscription,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/case-study',
        component: CaseStudy,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/meta-data-uploading',
        component: MetadataUploading,
        exact: true,
        protectedRoute: true,
    }
]

const UnprotectedRoute = (props) => {
    const token = localStorage.getItem('token')
    if (props.path === '/' && token) {
        history.push("/files")
        return null
    } else {
        return <Route {...props} />
    }
}

const ProtectedRoute = (props) => {
    const token = localStorage.getItem('token')
    if (!token) {
        history.push("/");
    }
    return token ? (
        <Navbar>
            <Route {...props} />
        </Navbar>
    ) : null
}

export const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                {routes.map(({ protectedRoute, ...item }, index) => {
                    return !protectedRoute ? (
                        <UnprotectedRoute {...item} key={index} />
                    ) : (
                        <ProtectedRoute {...item} key={index} />
                    )
                })}
                <Route component={NoMatch} />
            </Switch>
        </Router>
    )
}