import { NavLink } from 'react-router-dom'


export const tableData = (data, type) => {
    if (type === 'files') {
        return data.reduce((array, element, key) => [...array, {
            ...element, 'created': `${new Date(element.created).toLocaleDateString()} ${new Date(element.created).toLocaleTimeString()}`,
            'download_link': <NavLink to={{ pathname: "/data-editor", file: element.download_link }}>{element.download_link}</NavLink>,
            'hypothesis_link': <a href={element.hypothesis_link} target="blank">{element.hypothesis_link}</a>,
            'notes_link': <a href={element.notes_link} target="blank">{element.notes_link}</a>,
            'reviewed_by': element.reviewed_by[0] || '-', key
        }], [])
    }
    else if (type === 'users') {
        if (data) {
            return data.reduce((array, element, key) => [...array, {
                ...element, 'created': `${new Date(element.created).toLocaleDateString()} ${new Date(element.created).toLocaleTimeString()}`,
                'user_access': element.user_access.toString(), 'user_verified': element.user_verified.toString(), key
            }], [])
        }
    }
}
