export function toFixed(input, decimals) {
    if (typeof input === 'string') {
        throw new Error('toFixed() expects a number as input, but received a string.');
    }
    if (typeof decimals !== 'number') {
        throw new Error('toFixed() expects a number as decimals, but received a string.');
    }

    const decimalCount = input.toString().split('.')[1]?.length ?? 0

    return decimalCount <= decimals ? input : input.toFixed(decimals)
}