import React, { useState, useEffect } from 'react'
import { Card, Form, Button, Upload, notification, message, Input, Row } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { fileUploadApi, updateFileDetailsApi } from '../../services/apis'


export const FileUploadForm = ({ editRecord, setEditRecord, setRender }) => {

    const [state, setState] = useState({
        fileList: [],
        uploading: false,
    })
    const [editingState, setEditingState] = useState(false)
    const { uploading, fileList } = state
    const [form] = Form.useForm()
    const [updatedLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        if (Object.keys(editRecord).length !== 0) {
            setEditingState(true)
            const { description, notes_link, hypothesis_link, use_case, filename } = editRecord
            form.setFieldsValue({
                description, notes_link: notes_link.props.children, hypothesis_link: hypothesis_link.props.children, use_case, filename
            })
        }
    }, [editRecord, form, editingState])

    const formProps = {
        onRemove: file => {
            setState(state => {
                const index = state.fileList.indexOf(file)
                const newFileList = state.fileList.slice()
                newFileList.splice(index, 1)
                return {
                    fileList: newFileList,
                };
            });
        },
        beforeUpload: file => {
            setState(state => ({
                fileList: [...state.fileList, file]
            }))
            return false
        },
        fileList,
    }

    const handleFileUpload = (values) => {
        if ('filename' in values) {
            setUpdateLoading(true)
            updateFileDetailsApi(values).then((res) => {
                setUpdateLoading(false)
                notification.success({
                    message: 'File Details Updated',
                    placement: 'bottomRight'
                })
                setRender(prevState => prevState + 1)
            }).catch(err => setUpdateLoading(false))
        } else {
            let bodyFormData = new FormData()
            const { fileList } = state;
            fileList.forEach(file => {
                bodyFormData.append('_file', file)
            })
            delete values['upload']
            values.email = localStorage.getItem('user_email')
            values.user_name = localStorage.getItem('user_name')
            bodyFormData.append('payload', JSON.stringify(values))
            setState(state => ({
                ...state,
                uploading: true,
                data: [],
            }))
            fileUploadApi(bodyFormData)
                .then((response) => {
                    const result = response.data.result;
                    if (result.status === "Success") {
                        notification.success({
                            message: 'File Uploaded',
                            placement: 'bottomRight'
                        })
                        setState(state => ({
                            ...state,
                            fileList: [],
                            uploading: false
                        }))
                        form.resetFields()
                        setRender(prevState => prevState + 1)
                    }
                })
                .catch(error => {
                    message.error('upload failed.')
                    setState(state => ({
                        ...state,
                        uploading: false,
                    }))
                })
        }
    }

    return (
        <Card title="File Upload">
            <Form form={form} onFinish={handleFileUpload}>
                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Desscription required' }]}>
                    <Input placeholder="Enter Description" />
                </Form.Item>
                <Row justify="space-between">
                    {editingState &&
                        <Form.Item label="Filename" name="filename" >
                            <Input placeholder="Enter File Name" />
                        </Form.Item>
                    }
                    <Form.Item label="Notes Link" name="notes_link" rules={[{ type: 'url', message: 'Not a valid url' }]}>
                        <Input placeholder="Enter Notes link" />
                    </Form.Item>
                    <Form.Item label="Hypothesis Link" name="hypothesis_link" rules={[{ type: 'url', message: 'Not a valid url' }]}>
                        <Input placeholder="Enter Hypothesis link" />
                    </Form.Item>
                    <Form.Item label="Use case" name="use_case" rules={[{ required: true, message: 'Use Case required' }]}>
                        <Input placeholder="Enter Use case" />
                    </Form.Item>
                </Row>
                {!editingState &&
                    <>
                        <Form.Item name="upload">
                            <Upload  {...formProps}>
                                <Button disabled={fileList.length === 1}><UploadOutlined /> Select File</Button>
                            </Upload>
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            disabled={fileList.length === 0} loading={uploading}
                            style={{ marginTop: 16, marginBottom: 16 }}>
                            {uploading ? 'Uploading' : 'Start Upload'}
                        </Button>
                    </>
                }
                {editingState && <Button
                    htmlType="submit"
                    type="primary"
                    loading={updatedLoading}
                    disabled={updatedLoading}
                    style={{ marginTop: 16, marginBottom: 16 }}>
                    Confirm
                </Button>}
                <Button onClick={() => {
                    form.resetFields()
                    setEditingState(false)
                    setEditRecord({})
                    setState({
                        fileList: [],
                        uploading: false,
                    })
                }} type="dashed" style={{ marginLeft: 16 }}>Reset</Button>
            </Form>
        </Card>
    )
}
