import React, { useContext, useEffect, useRef } from "react";
import HighchartsReact from 'highcharts-react-official';
import { CollapsedContext } from '../../../App';

export const CustomHighChart = ({ options, ...props }) => {
    const chartRef = useRef(null)
    const { collapsed } = useContext(CollapsedContext)

    useEffect(() => {
        chartRef.current?.chart.reflow()
    }, [collapsed])

    return (
        <HighchartsReact
            ref={chartRef}
            options={{
                credits: {
                    enabled: false
                },
                ...options
            }}
            {...props}
        />
    );
};
