import React, { useEffect, useState } from "react";
import { message, Modal, Steps } from "antd";
import { NameAndCategoryForm } from './UnitConversionNameNCategoryForm'
import { AlternateValuesForm } from './UnitConversionAlternateValuesForm'
import { ConversionMetricForm } from './UnitConversionMetricsForm'
import { updateUnitConversion } from '../../../../services/apis'

const steps = [
    {
        title: 'Update Name and Category',
        content: NameAndCategoryForm,
    },
    {
        title: 'Update Alternative Values',
        content: AlternateValuesForm,
    },
    {
        title: 'Update Conversion Metric',
        content: ConversionMetricForm,
    },
];


export const UpdateUnitModal = ({ isVisible, closeModal, data, refetch }) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [unit, setUnit] = useState(null)
    useEffect(() => setUnit(data), [data])

    const next = () => setCurrentStep(currentStep + 1);
    const prev = () => setCurrentStep(currentStep - 1);
    const CurrentStep = steps[currentStep].content

    async function handleUpdateUnit() {
        const conversion_metric = {}
        Object.values(unit.conversion_metric).length !== 0 && unit.conversion_metric?.forEach(metric => {
            const splittedKey = metric.key.split('_')
            const key = `${unit.name}_to_${splittedKey[2]}`
            conversion_metric[key] = {
                [metric.conversion_method]: [
                    { "var": 0 },
                    Number(metric.value)
                ]
            }
        })

        const payload = {
            unit_id: unit.unit_id,
            name: unit.name,
            category: unit.category,
            alternative_values: unit.alternative_values,
            conversion_metric
        }

        try {
            const data = await updateUnitConversion(payload)
            if (data.data.result.access) {
                refetch()
                message.success('Unit updated successfully')
            }
            else message.error('There was an error creating the new unit')
        } catch (err) {
            console.log('ERROR CREATING NEW UNIT', err);
            message.error('There was an error creating the new unit')
        }

        setUnit(null)
        closeModal()
        setCurrentStep(0)
    }

    return (
        <Modal
            title="Add New Unit"
            footer={null}
            width={900}
            onCancel={() => {
                setUnit(null)
                setCurrentStep(0)
                closeModal()
            }}
            visible={isVisible}
        >
            <Steps current={currentStep}>
                {steps.map(step => {
                    return <Steps.Step key={step.title} title={step.title} />
                })}
            </Steps>
            <div style={{ marginTop: '32px' }}>
                {unit && (
                    <CurrentStep
                        unit={unit}
                        setUnit={setUnit}
                        next={next}
                        prev={prev}
                        finish={handleUpdateUnit}
                    />
                )}
            </div>
        </Modal >
    )
};