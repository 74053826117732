import { useHistory } from "react-router-dom"
import { Space, Layout, Row, Col, Form, Image, Card, Button, message } from 'antd'
import BigLogo from '../../assests/imgs/poly.png'
import firebase from 'firebase'
import { GooglePlusOutlined } from '@ant-design/icons'
import { tokenApi } from '../../services/apis'

const { Content: LayoutContent } = Layout


export const Login = () => {

    const { push } = useHistory()

    const goggleFirebaseLogin = () => {
        var provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                tokenApi({
                    email: result.additionalUserInfo.profile.email,
                    user_name: result.additionalUserInfo.profile.name,
                    token: result.credential.idToken
                }).then((res) => {
                    localStorage.setItem('token', res.data.access_token)
                    localStorage.setItem('access_level', res.data.access_level)
                    localStorage.setItem('user_email', res.data.email)
                    localStorage.setItem('user_name', res.data.user_name)
                    push('/files')
                }).catch(err => message.error('Accessible only to Polymerize Members'))
            }).catch((error) => {
                console.log(error)
                message.error('Error singing In')
            })
    }

    return (
        <Layout style={{ textAlign: 'center', minHeight: '100vh' }}>
            <LayoutContent style={{ overflow: 'auto' }}>
                <Row align="middle" justify="center" style={{ flex: 1 }}>
                    <Col xs={0} lg={7} offset={4}>
                        <Image src={BigLogo} preview={false} />
                    </Col>
                    <Col md={24} lg={13} style={{ marginTop: 100 }}>
                        <Space direction="vertical">
                            <Card title="Polymerize Internal Engineering">
                                <Form name="normal_login" className="login-form" layout='vertical' >
                                    <Button size="large" icon={<GooglePlusOutlined />} onClick={goggleFirebaseLogin} type="primary">
                                        Login with Google
                                    </Button>
                                </Form>
                            </Card>
                        </Space>
                    </Col>
                </Row>
            </LayoutContent>
        </Layout>
    )
}