import { Button, Card, Form, Input, message, PageHeader, Select, Space, Spin } from "antd"
import Text from "antd/lib/typography/Text"
import Title from "antd/lib/typography/Title"
import { useEffect, useState } from "react"
import { encryptDecryptDataApi, getCompaniesListApi } from "../../services/apis"

const { Option } = Select

export const DataSecurity = () => {
    const [form] = Form.useForm()
    const [companiesLoading, setCompaniesLoading] = useState(false)
    const [companiesList, setCompaniesList] = useState([])
    const [apiData, setApiData] = useState("")

    useEffect(() => {
        setCompaniesLoading(true)
        getCompaniesListApi().then((res) => {
            if (res.status === 200) {
                setCompaniesLoading(false)
                setCompaniesList(res.data.result.result.data.companies)
            } else {
                message.error('Not able to access companies')
            }
        }).catch((err) => {
            console.log('ERROR IN FETCHING COMPANIES', err);
        })
    }, [])

    const submitForm = (values) => {
        values.value = values.value.trim()
        encryptDecryptDataApi(values).then((res) => {
            setApiData(res?.data?.result?.data?.value)
        }).catch((err) => message.error("Something Went Wrong.Try Again."))
    }

    const validateInput = (value) => {
        if (!value.trim().length) {
            return Promise.reject('Enter Valid Input!.');
        }
        return Promise.resolve();
    };

    return (
        <>
            <PageHeader style={{ margin: "0 0 1.5rem 0", padding: 0 }}>
                <Card>
                    <Title level={5}>Data Encyrption/Decryption</Title>
                </Card>
            </PageHeader>
            <Card>
                <Spin spinning={companiesLoading}>
                    <Space direction="vertical" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Space direction="vertical" size="large">
                            <Form form={form} onFinish={submitForm}>

                                <Form.Item name={"action"} rules={[{ required: true }]} style={{ width: "300px" }}>
                                    <Select placeholder="Please Select an action" showSearch>
                                        <Option value={"encrypt"}>{"Encyrpt"}</Option>
                                        <Option value={"decrypt"}>{"Decrypt"}</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item name={"company_id"} rules={[{ required: true }]}>
                                    <Select placeholder="Please Select a Company Name" showSearch >
                                        {
                                            companiesList.map(({ company_id, company_name }) => (
                                                <Option value={company_id}>{company_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item name={"value"} validateFirst={true} rules={[{ required: true, message: "Required" }, {
                                    validator: (_, value) => validateInput(value),
                                }]}>
                                    <Input placeholder="Enter Value" minLength={2}></Input>
                                </Form.Item>

                                <Button htmlType="submit" type="primary" style={{ width: "100%" }}>Submit</Button>

                            </Form>
                            {apiData && <Text style={{ textAlign: "left" }}>{`${form.getFieldValue("action")?.[0].toUpperCase() + form.getFieldValue("action")?.substring(1)}ed value`}: <Text strong>{apiData}</Text></Text>}
                        </Space>
                    </Space>
                </Spin>
            </Card>
        </>
    )
}