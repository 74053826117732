import React from 'react'
import { Card, Form, Space, Button, Input } from "antd"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"


export const ModelNames = () => {
  return (
    <Card title="Model Names" type="inner">
      <Form.List name="model_names">
        {(fields, { add, remove }) => {
          return (
            <Space direction="vertical" size="large" style={{ width: "100%" }} align="center">
              {fields.map((field, i) => (
                <Space
                  key={field.key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="start"
                >
                  <Form.Item
                    style={{ width: 400 }}
                    {...field}
                    name={[field.name, "propertyName"]}
                    fieldKey={[field.fieldKey, "propertyName"]}
                    label="Object key"
                    key={i}
                    rules={[
                      {
                        required: true,
                        message: "Name Missing"
                      }
                    ]}
                  >
                    <Input placeholder="Property name" />
                  </Form.Item>
                  <Form.Item
                    style={{ width: 400 }}
                    {...field}
                    name={[field.name, "propertyValue"]}
                    label="Object value"
                    fieldKey={[field.fieldKey, "propertyValue"]}
                    key={i}
                    rules={[
                      {
                        required: true,
                        message: "Value Missing"
                      }
                    ]}
                  >
                    <Input placeholder="Property value" />
                  </Form.Item>

                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                  />
                </Space>
              ))}
              <Button type="dashed" onClick={() => add()} block>
                <PlusOutlined /> Add Model name
              </Button>
            </Space>
          )
        }}
      </Form.List>
    </Card>
  )
}
