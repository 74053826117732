import React, { useMemo } from "react";
import { Table } from 'antd';
import { RendererWrapper } from './RendererWrapper';
import { toFixed } from '../../../utils'

export const CleanData = ({ data }) => {

    const cols = useMemo(() => {
        return [
            {
                title: '',
                dataIndex: 'ingredient',
                key: 'ingredient',
                fixed: 'left',
                render: (text) => <strong className='table-first-col'>{text}</strong>
            },
            ...Object.keys(data[Object.keys(data)[0]]).map(col => ({
                title: col,
                dataIndex: col,
                key: col,
                render: (text) => (
                    <span className="table-head-col">
                        {typeof text === 'number' ? toFixed(text, 3) : text}
                    </span>
                )
            }))]
    }, [data])

    const dataSource = useMemo(() => {
        return Object.keys(data).map(key => ({
            key,
            ingredient: key,
            ...data[key]
        }))
    }, [data])

    return (
        <RendererWrapper heading='CLEAN DATA'>
            <Table
                className='table'
                dataSource={dataSource}
                bordered
                pagination={false}
                columns={cols}
            />
        </RendererWrapper>
    )
};
