import React from "react";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { RendererWrapper, CustomHighChart } from '.';
import { Col, Row, Divider } from 'antd';
import { blue } from '@ant-design/colors';

HighchartsMore(Highcharts);

export const IngredientsOutlier = ({ data }) => {
    const groupedIngredients = Object.keys(data['limits']).map((limit) => {
        const outlier = data['outliers'][limit]
        return {
            key: limit,
            observation: [data['limits'][limit]],
            outlier: outlier ? outlier.map(item => ({ name: item.index, x: 0, y: item.value })) : null,
            xAxis: outlier ? outlier.map(item => item.index) : []
        }
    })
    const ingredientsWithOutlier = groupedIngredients.filter(item => item.outlier !== null)
    const ingredientsWithoutOutlier = groupedIngredients.filter(item => item.outlier === null)

    const getOptions = (data) => {
        return {
            chart: { type: 'boxplot' },
            title: {
                text: data.key.split('_').join(' '),
                style: {
                    fontSize: '16px'
                }
            },
            legend: { enabled: true },
            xAxis: {
                categories: data.xAxis,
                visible: false
            },
            yAxis: {
                title: { text: '' },
                // plotLines: [{
                //     value: 932,
                //     color: 'red',
                //     width: 1,
                //     label: {
                //         text: 'Theoretical mean: 932',
                //         align: 'center',
                //         style: { color: 'gray' }
                //     }
                // }]
            },
            series: [{
                name: 'Observations',
                data: data.observation,
                tooltip: { headerFormat: '' }
            }, {
                name: 'Outliers',
                color: Highcharts.getOptions().colors[0],
                type: 'scatter',
                data: data.outlier,
                marker: {
                    fillColor: 'white',
                    lineWidth: 1,
                    lineColor: Highcharts.getOptions().colors[0]
                },
                tooltip: {
                    headerFormat: '<strong>Experiment: {point.key}</strong><br/>',
                    pointFormat: 'Value: {point.y}'
                }
            }]

        }
    }

    return (
        <RendererWrapper heading='INGREDIENTS OUTLIER'>
            <Row style={{ fontSize: '30px', color: blue.primary }}>Ingredients with Outliers</Row>
            <Row gutter={[50, 32]}>
                {ingredientsWithOutlier.length ? ingredientsWithOutlier.map((data) => {
                    return (
                        <Col key={data.key} span={6}>
                            <CustomHighChart
                                highcharts={Highcharts}
                                options={getOptions(data)}
                            />
                        </Col>
                    )
                }) : (
                    <Col>No Data Available</Col>
                )}
            </Row>

            <Divider />

            <Row style={{ fontSize: '30px', color: blue.primary }}>Ingredients without Outliers</Row>
            <Row gutter={[50, 32]}>
                {ingredientsWithoutOutlier.length ? ingredientsWithoutOutlier.map((data) => {
                    return (
                        <Col key={data.key} span={6}>
                            <CustomHighChart
                                highcharts={Highcharts}
                                options={getOptions(data)}
                            />
                        </Col>
                    )
                }) : (
                    <Col>No Data Available</Col>
                )}
            </Row>
        </RendererWrapper>
    );
};
